import { Button, styled, useTheme } from '@mui/material';
import {
  ACTION_BAR_HEIGHT,
  SIDEBAR_SECTIONS_WITH_PROGRESS_BAR_WIDTH,
} from '../../../constants/layoutSizes';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ActionButtonsFooter } from '../../../components/ActionButtonsFooter/ActionButtonsFooter';
import { useCallback, useMemo, useState } from 'react';
import { useAtom } from 'jotai';
import { activeReportState, activeSurveyState, responsesState } from '../../../state/UIState';
import {
  LoadingId,
  QUESTION_TYPE,
  QuestionOption,
  REPORT_STATUS,
  SurveyQuestion,
} from '../../../types';
import { useLoadingBar } from '../../../hooks/useLoadingBar';
import { REPORTS, useUpdateReport, useUpdateReportWithToken } from '../../../queries/useReports';
import { useInternalQuestionnaireSubmit } from './hooks/useInternalQuestionnaireSubmit';
import { useNavigate } from 'react-router';
import { flattenTree } from '../../../utils/treeUtilities';
import { useNextSurveyData } from './hooks/useNextSurveyData';
import { ReactComponent as RightArrow } from '../../../assets/icons/arrow-right.svg';
import { useQueryClient } from 'react-query';
import { useCompanyById, useUpdateCompany } from '../../../queries/useCompanies';
import { useScreeningFlowSurveys } from './hooks/useScreeningFlowSurveys';

const IconWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-left: 12px;

  svg {
    width: 6px;
  }
  path {
    fill: #fff;
  }
`;

const SubmitIcon = () => {
  return (
    <IconWrapper>
      <RightArrow />
    </IconWrapper>
  );
};

const ActionsWrapper = styled('div')`
  margin-left: ${SIDEBAR_SECTIONS_WITH_PROGRESS_BAR_WIDTH}px;
  position: relative;
  height: ${ACTION_BAR_HEIGHT}px;
`;

const EditButtonIcon = styled(EditIcon)`
  path {
    fill: ${({ theme }) => theme.colors.accent[50]};
  }
`;

interface Props {
  isInternal?: boolean;
}

export const FooterButtons = ({ isInternal }: Props) => {
  const [activeReport] = useAtom(activeReportState);
  const [isLoading, setIsLoading] = useState(false);
  const { startLoading, stopLoading } = useLoadingBar();
  const navigate = useNavigate();
  const { colors } = useTheme();
  const queryClient = useQueryClient();

  const { data: company } = useCompanyById(activeReport?.company?.id);
  const { mutateAsync: updateCompany } = useUpdateCompany();

  const [response] = useAtom(responsesState);
  const [activeSurvey] = useAtom(activeSurveyState);

  const updateReportWithToken = useUpdateReportWithToken();

  const { onSubmitInternal } = useInternalQuestionnaireSubmit(isInternal);

  const nextSurveyData = useNextSurveyData({ companyId: activeReport?.company?.id, isInternal });
  const updateReport = useUpdateReport();

  const { allAssessmentsStepsData } = useScreeningFlowSurveys(activeReport?.company?.id);

  const onSubmit = useCallback(async () => {
    if (!activeReport) return;
    setIsLoading(true);
    startLoading(LoadingId.completeReport);
    if (isInternal) {
      await onSubmitInternal();
      setIsLoading(false);
      return;
    }
    await updateReportWithToken.mutateAsync(
      { status: REPORT_STATUS.IN_REVIEW },
      {
        onSuccess: () => {
          navigate(`/esg-portal/done`);
        },
        onSettled: () => {
          setIsLoading(false);
          stopLoading(LoadingId.completeReport);
        },
      }
    );

    // await sendEvaluationInvites.mutateAsync(activeReport.id);
  }, [
    activeReport,
    startLoading,
    isInternal,
    onSubmitInternal,
    updateReportWithToken,
    navigate,
    stopLoading,
  ]);

  const isSubmitDisabled = useMemo(() => {
    //check if all required questions and subquestions are answered
    if (!activeSurvey || !response) return true;
    const allSections = flattenTree(activeSurvey.sections);
    const allQuestions = allSections.reduce((acc: SurveyQuestion[], section) => {
      const subQuestions: SurveyQuestion[] = [];
      for (const question of section.questions) {
        if (
          question.type === QUESTION_TYPE.SINGLE &&
          question.includeConditions &&
          response[question.id]
        ) {
          const option = response[question.id] as QuestionOption;
          option?.subQuestions && subQuestions.push(...option.subQuestions);
        }
      }
      return [...acc, ...section.questions, ...subQuestions];
    }, []);
    const requiredQuestions = allQuestions.filter((question) => question.isRequired);
    const answeredQuestions = requiredQuestions.filter((question) => response[question.id]);
    return requiredQuestions.length !== answeredQuestions.length;
  }, [response, activeSurvey]);

  const submitText = useMemo(() => {
    if (!nextSurveyData || !isInternal) return 'Submit Report';

    return `Next: ${nextSurveyData.surveyName}`;
  }, [isInternal, nextSurveyData]);

  const onEditSubmission = async () => {
    startLoading(LoadingId.editSubmission);
    setIsLoading(true);
    await updateReport.mutateAsync(
      { id: activeReport?.id, status: REPORT_STATUS.IN_PROGRESS },
      {
        onSuccess: async () => {
          const currentStep = allAssessmentsStepsData?.find(
            (stepData) => activeReport?.survey.id === stepData?.surveyId
          )?.step;

          const newCompletedSteps = company?.completedWorkflowSteps?.filter(
            (step) => step !== currentStep?.id
          );
          await updateCompany({
            id: company?.id,
            completedWorkflowSteps: newCompletedSteps,
          });
          queryClient.refetchQueries(`${REPORTS}-${activeReport?.company.id}`);
          queryClient.refetchQueries(`${REPORTS}-${activeReport?.id}`);
        },
        onError: (e) => {
          console.error(e);
        },
        onSettled: () => {
          stopLoading(LoadingId.editSubmission);
          setIsLoading(false);
        },
      }
    );
  };

  return (
    <ActionsWrapper>
      <ActionButtonsFooter
        onSubmit={onSubmit}
        isSubmitDisabled={isLoading || isSubmitDisabled}
        submitText={submitText}
        bgColor={colors.primary[0]}
        submitIcon={nextSurveyData ? <SubmitIcon /> : null}
        secondaryButton={
          activeReport?.status === REPORT_STATUS.SUBMITTED ? (
            <Button
              variant='outlined'
              style={{ width: '173px' }}
              onClick={onEditSubmission}
              startIcon={<EditButtonIcon />}
            >
              Edit Submission
            </Button>
          ) : undefined
        }
      />
    </ActionsWrapper>
  );
};
