import { useQueryClient } from 'react-query';
import { REPORTS, useUpdateReport } from '../../../../queries/useReports';
import { LoadingId, QuestionOption, REPORT_STATUS } from '../../../../types';
import { useAtom } from 'jotai';
import { activeReportState, responsesState } from '../../../../state/UIState';
import { useNextSurveyData } from './useNextSurveyData';
import { ROUTES } from '../../../../constants/routes';
import { useNavigate } from 'react-router';
import { useReportTaskClickHandler } from '../../../../hooks/useReportTaskClickHandler';
import { useOnReportClick } from '../../../../hooks/useOnReportClick';
import { useLoadingBar } from '../../../../hooks/useLoadingBar';
import { useCallback } from 'react';

import { useCompanyById, useUpdateCompany } from '../../../../queries/useCompanies';
import { useScreeningFlowSurveys } from './useScreeningFlowSurveys';
import { useNotifyUsers } from '../../../../queries/useNotifyUsers';
import {
  DUE_DILIGENCE_SURVEY_NAME,
  INVESTOR_EXCLUSIONS_SURVEY_ID,
} from '../../../../constants/constants';

export const useInternalQuestionnaireSubmit = (isInternal?: boolean) => {
  const navigate = useNavigate();

  const updateReport = useUpdateReport();
  const queryClient = useQueryClient();
  const { onReportTaskClickHandler } = useReportTaskClickHandler();
  const { onReportClick } = useOnReportClick();

  const { stopLoading } = useLoadingBar();

  const [activeReport] = useAtom(activeReportState);
  const [response] = useAtom(responsesState);

  const { data: company } = useCompanyById(activeReport?.company?.id);
  const { mutate: updateCompany } = useUpdateCompany();

  const nextSurveyData = useNextSurveyData({ companyId: activeReport?.company?.id, isInternal });

  const { allAssessmentsStepsData, allAssessmentsSteps } = useScreeningFlowSurveys(
    activeReport?.company?.id
  );

  const { mutate: notifyUsers } = useNotifyUsers();

  const onSubmitInternal = useCallback(async () => {
    if (!activeReport || !company) return null;

    const currentStep = allAssessmentsStepsData?.find(
      (stepData) => activeReport?.survey.id === stepData?.surveyId
    )?.step;

    const status = !currentStep?.isApprovalRequired
      ? REPORT_STATUS.SUBMITTED
      : REPORT_STATUS.IN_REVIEW;

    await updateReport.mutateAsync(
      { id: activeReport.id, status },
      {
        onSuccess: async () => {
          queryClient.refetchQueries(`${REPORTS}-${company.id}`);
          queryClient.refetchQueries(`${REPORTS}-${activeReport.id}`);

          if (status === REPORT_STATUS.SUBMITTED) {
            activeReport?.status !== REPORT_STATUS.SUBMITTED &&
              updateCompany({
                id: company.id,
                completedWorkflowSteps: currentStep
                  ? [...(company?.completedWorkflowSteps || []), currentStep?.id]
                  : company?.completedWorkflowSteps,
              });
          }

          if (activeReport?.survey?.id === INVESTOR_EXCLUSIONS_SURVEY_ID) {
            const responses = Object.values(response);
            const hasFlaggedConditions = responses.some(
              (response) => (response as QuestionOption).name === 'Yes'
            );
            const dueDiligenceExclusionStep = allAssessmentsSteps?.find(
              (step) => step?.name === DUE_DILIGENCE_SURVEY_NAME
            );

            if (!hasFlaggedConditions) {
              const stepIds =
                status === REPORT_STATUS.SUBMITTED && currentStep
                  ? [currentStep.id, dueDiligenceExclusionStep?.id as number]
                  : [dueDiligenceExclusionStep?.id as number];

              updateCompany({
                id: company.id,
                completedWorkflowSteps: currentStep
                  ? [...(company?.completedWorkflowSteps || []), ...stepIds]
                  : company?.completedWorkflowSteps,
              });
            }
          }

          if (currentStep?.isApprovalRequired) {
            notifyUsers({
              companyId: company.id,
              userIds: currentStep?.notifyUsers?.map((user) => user.id) || [],
              roleIds: currentStep?.approvalRoles?.map((role) => role.id) || [],
            });
          }

          if (!nextSurveyData) {
            navigate(`/${ROUTES.COMPANIES}/${company.id}/${ROUTES.COMPANY_OVERVIEW}`);
            return;
          }

          if (nextSurveyData && !nextSurveyData.report) {
            onReportTaskClickHandler(
              nextSurveyData.report,
              nextSurveyData?.surveyId,
              nextSurveyData?.surveyName,
              nextSurveyData?.companyId
            );

            return;
          }

          if (nextSurveyData.report) {
            onReportClick(nextSurveyData.report);
          }
        },
        onSettled: () => {
          stopLoading(LoadingId.completeReport);
        },
      }
    );
  }, [
    activeReport,
    allAssessmentsSteps,
    allAssessmentsStepsData,
    company,
    navigate,
    nextSurveyData,
    notifyUsers,
    onReportClick,
    onReportTaskClickHandler,
    queryClient,
    response,
    stopLoading,
    updateCompany,
    updateReport,
  ]);

  return { onSubmitInternal };
};
