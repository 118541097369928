import { useAtom } from 'jotai';
import { activeSurveyState } from '../../../../state/UIState';
import { useMemo } from 'react';
import { REPORT_STATUS } from '../../../../types';
import { useScreeningFlowSurveys } from './useScreeningFlowSurveys';
import { useHasAccessToTask } from '../../../../hooks/useHasAccessToTask';

export const useNextSurveyData = ({ companyId }: { isInternal?: boolean; companyId?: number }) => {
  const { nonApprovalAssessmentsStepData } = useScreeningFlowSurveys(companyId);
  const { getHasAccess } = useHasAccessToTask();

  const [activeSurvey] = useAtom(activeSurveyState);

  return useMemo(() => {
    if (!nonApprovalAssessmentsStepData?.length) return;

    return nonApprovalAssessmentsStepData?.filter((data) => {
      const hasAccessToTask = getHasAccess(data.step);
      return (
        data?.surveyId !== activeSurvey?.id &&
        (!data?.report ||
          data.report.status === REPORT_STATUS.IN_PROGRESS ||
          data.report.status === REPORT_STATUS.NOT_STARTED ||
          data.report.status === REPORT_STATUS.REJECTED) &&
        hasAccessToTask
      );
    })[0];
  }, [nonApprovalAssessmentsStepData, getHasAccess, activeSurvey?.id]);
};
